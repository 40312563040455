<script>
  export let fillColor = "none";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 -960 960 960"
  width="24px"
  fill={fillColor}
  ><path
    d="m442.5-646-193 193Q238-441.5 223-441.75t-26.5-11.75q-11-11.5-11.25-26.5t11.25-26.5l257-257q5.5-5.5 12.25-8.25T480-774.5q7.5 0 14.25 2.75t12.25 8.25l257 257q11 11 11 26.25t-11 26.75Q752-442 736.75-442T710-453.5L517.5-646v438.5q0 15.5-11 26.5T480-170q-15.5 0-26.5-11t-11-26.5V-646Z"
  /></svg
>

<style>
  svg {
    width: 20px;
    height: 20px;
  }
</style>
