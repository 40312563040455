<script>
  export let fillColor = "none";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 -960 960 960"
  width="24px"
  fill={fillColor}
  ><path
    d="M241-479q0 16 2 31.5t7 31q4.5 15.5-1 30t-20 21q-15 7-29.25.75T180.5-386.5q-7.5-22.5-11-45.75T166-479q0-131.5 91.25-223.25T481-794h10l-38.5-38.5q-11-11-11-26.5t11-26.5q11-11 26.5-11t26.5 11l102.5 103q11.5 11.5 11.25 26.5t-11.75 26.5l-102 102q-11 11-26.5 11t-26.5-11q-11-11-11-26.5t11-26.5L491-719h-10q-100.5 0-170.25 69.75T241-479Zm478-4q0-16-2-31t-6.5-30q-4.5-15.5 1-30.5t20-21.5q15-7 29-1t19 21.5q7.5 22.5 11 45.75T794-483q0 131.5-91.25 223.25T479-168h-10l38.5 38.5q11 11 11 26.5t-11 26.5q-11 11-26.5 11t-26.5-11L352-179.5Q340.5-191 340.75-206t11.75-26.5l102-102q11-11 26.5-11t26.5 11q11 11 11 26.5t-11 26.5L469-243h10q100.5 0 170.25-69.75T719-483Z"
  /></svg
>
