<script lang="ts">
  import { createEventDispatcher, onMount, tick } from "svelte";
  import sendIcon from "../assets/images/arrow.up.icon.svg";
  import { sendMessage, sendQr } from "../service/service";
  import {
    apiTokenValue,
    messageList,
    sendMessageLoading,
    botInfo,
    fetchMessageLoading,
  } from "../store";
  import type { QuickReply } from "../service/service";
  import SkeletonAvatar from "../SharedComponent/SkeletonAvatar.svelte";
  import Logo from "../Logo.svelte";
  import SendIcon from "../SharedComponent/SendIcon.svelte";
  export let expanded: boolean;

  let formData = {
    message: "",
  };

  let inputElement: HTMLTextAreaElement;
  const dispatch = createEventDispatcher();

  onMount(() => {
    inputElement.focus();
  });

  export async function handleQuickReply(qr: QuickReply) {
    const qrId = qr.id;
    const message = qr.question;

    messageList.update((messages) => [...messages, { content: message, role: "user" }]);
    dispatch("messageReceived", true);
    formData.message = "";
    await tick();
    inputElement.focus();
    sendMessageLoading.set(true);
    try {
      const sentMessage = await sendQr(qrId, $apiTokenValue);
      messageList.update((messages) => [...messages, sentMessage]);
      dispatch("messageReceived", true);
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      sendMessageLoading.set(false);
      inputElement.focus();
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if ($sendMessageLoading || !formData.message || formData.message.length === 0) {
      return;
    }
    const message = { ...formData }.message;

    messageList.update((messages) => [...messages, { content: message, role: "user" }]);
    dispatch("messageReceived", true);
    formData.message = "";
    await tick();
    inputElement.focus();
    sendMessageLoading.set(true);
    try {
      const receivedMessage = await sendMessage(message, $apiTokenValue);
      console.log(receivedMessage);

      messageList.update((messages) => [...messages, receivedMessage]);
      dispatch("messageReceived", true);
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      sendMessageLoading.set(false);
      inputElement.focus();
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        handleSubmit(event);
      }
    }
  }
  export const directionDetector = (message?: string): "rtl" | "ltr" => {
    if (!message || message.trim().length === 0) {
      return "ltr";
    }
    const firstChar = message.slice(0, 1);

    const arabicChars = (firstChar.match(/[\u0600-\u06FF]/g) || []).length;
    if (arabicChars) {
      return "rtl";
    }

    return "ltr";
  };
</script>

<form
  class="rizo-chat-box-input"
  class:rizo-chat-box-input-expanded={expanded}
  on:submit={handleSubmit}
  style="--input-background-color: {$botInfo?.settings?.widgetAppearanceBehavior
    ?.widgetTextFieldColor || '#ffffff'};--input-border-color: {$botInfo?.settings
    ?.widgetAppearanceBehavior?.widgetTextFieldBorderColor || '#d2d2d2'};
      --input-border-width: {$botInfo?.settings?.widgetAppearanceBehavior
    ?.widgetTextFieldBorderWidth
    ? $botInfo?.settings?.widgetAppearanceBehavior?.widgetTextFieldBorderWidth + 'px'
    : '1px'};
      "
>
  <span
    class="rizo-chat-input-dummy-icon"
    style="--avatar-stroke-color: {$botInfo?.settings?.widgetAppearanceBehavior
      ?.widgetUseAvatarStrokeColor || '#eaeaea'};"
  >
    <!-- {#if $botInfo?.settings?.botImageUrl}
      <img src={$botInfo?.settings?.botImageUrl} alt="logo" />
    {:else}
      <Logo strokeColor={"#4b00cc"} width={"30"} height={"30"} />
    {/if} -->
  </span>

  <textarea
    id="message"
    placeholder={$botInfo?.settings?.widgetAppearanceBehavior?.widgetPlaceholderText || "Ask"}
    bind:value={formData.message}
    bind:this={inputElement}
    required
    on:keydown={handleKeyDown}
    style="direction: {directionDetector(formData.message)};--placeholder-text-color: {$botInfo
      ?.settings?.widgetAppearanceBehavior?.widgetPlaceholderTextColor || '#d2d2d2'};"
  />

  {#if $fetchMessageLoading}
    <span class="rizo-dummy-submit">
      <SkeletonAvatar />
    </span>
  {:else}
    <button
      type="submit"
      disabled={$sendMessageLoading || formData.message?.trim().length === 0}
      style="--element-background-color: {$botInfo?.settings?.widgetAppearanceBehavior
        ?.widgetSendButtonColor || '#4b00cc'};"
    >
      <SendIcon
        fillColor={$botInfo?.settings?.widgetAppearanceBehavior?.widgetTextFieldColor || "#ffffff"}
      />
      <!-- <img src={sendIcon} alt="send" /> -->
    </button>
  {/if}
</form>

<style>
  .rizo-chat-box-input {
    height: 30px;
    background-color: var(--input-background-color);
    border-color: var(--input-border-color);
    border-width: var(--input-border-width);
    border-style: solid;

    /* background-color: rgb(255, 255, 255); */
    /* box-shadow: 0 0 0 2px rgba(135, 5, 255, 0.1); */
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    gap: 10px;
    margin: 10px;
  }

  @media (max-width: 767px) {
    .rizo-chat-box-input {
      margin: 10px 0px;
    }
  }
  .rizo-chat-box-input > textarea {
    margin: 0px;
    flex: 1;
    height: 30px;
    border: none;
    outline: none;
    padding: 0px 5px;
    outline: none;
    font-weight: normal;
    padding-top: 5px;
    background-color: transparent;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    /* font-size: 10px; */
    /* to disable zoom in iOS */

    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 767px) and (max-width: 991px) {
    .rizo-chat-box-input > textarea {
      /* font-size: 11px; */
      /* to disable zoom in iOS */
      font-size: 16px;

      /* line-height: 22px; */
    }
  }

  @media (min-width: 991px) {
    .rizo-chat-box-input > textarea {
      /* font-size: 14px; */
      /* to disable zoom in iOS */

      font-size: 16px;

      /* line-height: 26px; */
    }
  }
  .rizo-chat-input-dummy-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: var(--avatar-stroke-color);
    box-sizing: border-box;
    align-self: center;
  }

  /* .rizo-chat-input-dummy-icon img {
    width: 26px;
    height: 26px;
  } */
  .rizo-chat-box-input-expanded {
    height: 75px;
  }
  .rizo-chat-box-input-expanded > textarea {
    height: 50px;
  }
  .rizo-chat-box-input > textarea::placeholder {
    line-height: inherit;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: var(--placeholder-text-color);
  }
  .rizo-chat-box-input > button {
    border: none;
    outline: none;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--element-background-color);
    padding: 0px;
  }
  .rizo-chat-box-input > button:disabled {
    /* background-color: #d2d2d2; */
    cursor: not-allowed;
  }
  .rizo-chat-box-input > button > img {
    width: 20px;
  }
  .rizo-dummy-submit {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
</style>
