import { __awaiter } from "tslib";
import { BASE_URL } from "../config/config";
// src/services/chatService.ts
export var WidgetViewMode;
(function (WidgetViewMode) {
    WidgetViewMode["LEGACY"] = "legacy";
    WidgetViewMode["DEFAULT"] = "default";
    WidgetViewMode["EXPAND"] = "expand";
    WidgetViewMode["PIN"] = "pin";
})(WidgetViewMode || (WidgetViewMode = {}));
function isUUID(uuid) {
    const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    return uuidRegex.test(uuid);
}
export function initializeWidget(BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        const conversationId = localStorage.getItem("conversationId");
        if (!conversationId) {
            return createConversation(BOT_ACCESS_TOKEN);
        }
        if (!isUUID(conversationId)) {
            return createConversation(BOT_ACCESS_TOKEN);
        }
        return fetchMessages(conversationId, BOT_ACCESS_TOKEN);
    });
}
export function fetchMessages(conversationId, BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            // console.log(window.location.href);
            if (!BOT_ACCESS_TOKEN) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const response = yield fetch(`${BASE_URL}/widget/conversation/${conversationId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
            });
            if (!response.ok) {
                const errorData = yield response.json();
                if (errorData.message === 40101) {
                    localStorage.removeItem("conversationId");
                    return createConversation(BOT_ACCESS_TOKEN);
                }
                throw new Error("Failed to fetch messages");
            }
            const data = yield response.json();
            return Promise.resolve(data.data);
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            // return Promise.resolve([]);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
export function createConversation(BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!BOT_ACCESS_TOKEN) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const response = yield fetch(`${BASE_URL}/widget/conversation`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
                body: JSON.stringify({
                    entry: window.location.href,
                }),
            });
            if (!response.ok)
                throw new Error("Failed to create conversation");
            const data = yield response.json();
            const conversationId = data.data.id;
            localStorage.setItem("conversationId", conversationId);
            return fetchMessages(conversationId, BOT_ACCESS_TOKEN);
            // return Promise.resolve(response.json());
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            // return Promise.resolve([]);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
export function sendMessage(message, BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!BOT_ACCESS_TOKEN) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const conversationId = localStorage.getItem("conversationId");
            const response = yield fetch(`${BASE_URL}/widget/conversation/chat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
                body: JSON.stringify({ message, conversationId, ignoreMemory: false }),
            });
            if (!response.ok)
                throw new Error("Failed to send message");
            const data = yield response.json();
            return Promise.resolve({ content: data.data, role: "assistant", metadata: data.metadata });
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
export function sendQr(id, BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!BOT_ACCESS_TOKEN) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const conversationId = localStorage.getItem("conversationId");
            const response = yield fetch(`${BASE_URL}/widget/conversation/chat/quick-reply`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
                body: JSON.stringify({ id, conversationId }),
            });
            if (!response.ok)
                throw new Error("Failed to send message");
            const data = yield response.json();
            return Promise.resolve({
                content: data.data.data,
                role: "assistant",
                metadata: data.data.metadata,
            });
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
export function sendFeedBack(assistantMessageId, isPositive, BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!BOT_ACCESS_TOKEN) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const conversationId = localStorage.getItem("conversationId");
            const response = yield fetch(`${BASE_URL}/widget/conversation/chat/feedback`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
                body: JSON.stringify({ assistantMessageId, isPositive }),
            });
            if (!response.ok)
                throw new Error("Failed to send message");
            const data = yield response.json();
            return Promise.resolve(isPositive);
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
export function sendContactInfo(items, BOT_ACCESS_TOKEN) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const conversationId = localStorage.getItem("conversationId");
            if (!BOT_ACCESS_TOKEN || !conversationId) {
                throw new Error("BOT_ACCESS_TOKEN must be defined");
            }
            const response = yield fetch(`${BASE_URL}/widget/conversation/extra-info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": BOT_ACCESS_TOKEN,
                },
                body: JSON.stringify({ conversationId, items }),
            });
            if (!response.ok)
                throw new Error("Failed to send message");
            const data = yield response.json();
            return Promise.resolve();
        }
        catch (error) {
            console.error("Rizo Chat Widget :", error);
            return Promise.reject((error === null || error === void 0 ? void 0 : error.message) || "unknown error");
        }
    });
}
