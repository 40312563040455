<script lang="ts">
  import {
    fetchMessageLoading,
    isWidgetOpen,
    sendMessageLoading,
    messageList,
    apiTokenValue,
    botInfo,
  } from "./store";
  import ChatBox from "./ChatBox.svelte";
  import WidgetButton from "./WidgetButton.svelte";
  import { onMount } from "svelte";
  import { initializeWidget, WidgetViewMode } from "./service/service";
  import LegacyChatBox from "./LegacyChatBox.svelte";
  import PinnedChatBox from "./pin.view.mode/PinnedChatBox.svelte";
  export let apiToken: string;

  const widgetMode: WidgetViewMode = WidgetViewMode.PIN;

  //---------temp----------//

  let widgetFontColor = "blue";

  //---------temp----------//
  let showWidget: boolean = false;
  let loadWidget: boolean = false;
  let aiMessageConverter: any;
  let userMessageConverter: any;
  onMount(() => {
    initialize();
  });

  function initialize() {
    try {
      if (!apiToken) {
        throw new Error("API Token is required.");
      }
      apiTokenValue.set(apiToken);
      getAllMessages();
    } catch (error) {
      console.log("Rizo Chat Widget :", error);
    }
  }

  async function getAllMessages() {
    fetchMessageLoading.set(true);
    const { messages, project } = await initializeWidget($apiTokenValue);
    botInfo.set(project);
    messageList.set(messages);
    fetchMessageLoading.set(false);

    showWidget = true;
  }

  const initializeShowdown = () => {
    const directionExtension = {
      type: "output",
      filter: (text: string): string => {
        return text.replace(
          /<(p|h[1-6]|span|li|ol|ul)(\s[^>]*)?>([\s\S]*?)<\/\1>/g,
          (match: string, tag: string, attributes: string = "", content: string) => {
            const trimmedContent = content.trim();

            // Count Arabic characters
            const arabicChars = (trimmedContent.match(/[\u0600-\u06FF]/g) || []).length;
            // Count English letters
            const englishChars = (trimmedContent.match(/[a-zA-Z]/g) || []).length;

            // Determine direction based on counts
            const dir = arabicChars > englishChars ? "rtl" : "ltr";

            // Return the original tag with the direction attribute added
            return `<${tag}${attributes} dir="${dir}">${content}</${tag}>`;
          }
        );
      },
    };

    aiMessageConverter = new window.showdown.Converter({
      extensions: [directionExtension],
    });

    userMessageConverter = new window.showdown.Converter({
      extensions: [directionExtension],
    });
    loadWidget = true;
  };

  export function initRizo(apiToken: string) {
    apiToken = apiToken;
    initialize();
  }
</script>

<svelte:head>
  <script
    src="https://cdn.jsdelivr.net/npm/showdown@1.9.1/dist/showdown.min.js"
    on:load={initializeShowdown}
  ></script>
</svelte:head>

<div class="rizo-chat-container">
  {#if loadWidget && apiToken && showWidget}
    {#if $botInfo.settings?.widgetViewMode === "pin"}
      <PinnedChatBox {aiMessageConverter} {userMessageConverter} />
    {:else if $botInfo.settings?.widgetViewMode === "legacy"}
      <LegacyChatBox {aiMessageConverter} {userMessageConverter} />
    {:else if $isWidgetOpen}
      <ChatBox {aiMessageConverter} {userMessageConverter} />
    {:else}
      <WidgetButton />
    {/if}
  {/if}
</div>

<style>
  .rizo-chat-container {
    z-index: 100000;
    display: block;
    width: 100%;
  }
</style>
