<script lang="ts">
  import { apiTokenValue, botInfo } from "./store";
  import Feedback from "./Feedback.svelte";
  import { sendFeedBack, type AssistantFeedback } from "./service/service";
  export let assistantMessageId: string | null = null;
  export let assistantFeedback: AssistantFeedback | null = null;
  export let role: string;
  export let content: string;
  export let aiMessageConverter: any;
  export let userMessageConverter: any;
  export let expanded: boolean;
  //---------temp----------//

  let widgetFontColor = "#ddd222";

  //---------temp----------//
  let currentFeedback: "good" | "bad" | "unknown" = "unknown";

  async function sendFeedback(assistantMessageId: string | null, isPositive: boolean) {
    if (!assistantMessageId) return;
    currentFeedback = isPositive ? "good" : "bad";
    const result = await sendFeedBack(assistantMessageId, isPositive, $apiTokenValue);
  }
</script>

<div
  class={`rizo-chat-box-message-container ${role === "user" ? "rizo-right-aligned" : "rizo-left-aligned"}`}
>
  <div
    class={`rizo-chat-single-message ${role === "user" ? "rizo-chat-user-message" : "rizo-chat-assistant-message"}`}
    class:rizo-chat-single-message-expanded={expanded}
    style="background-color: {role === 'user'
      ? $botInfo?.settings?.widgetBackgroundColor || '#4b00cc'
      : '#f2f0f7'};"
  >
    <div
      class={`rizo-message-content ${role === "user" ? "rizo-message-content-user" : "rizo-message-content-assistant"}`}
      style="--widget-font-color: {$botInfo?.settings?.widgetFontColor ||
        widgetFontColor ||
        '#ffffff'};"
    >
      {@html role === "user"
        ? userMessageConverter.makeHtml(content)
        : aiMessageConverter.makeHtml(content)}
    </div>

    {#if role === "assistant" && assistantMessageId && !assistantFeedback}
      <span class={"rizo-message-feedback-container"}>
        {#if ["unknown", "bad"].includes(currentFeedback)}
          <button
            class="rizo-message-feedback-dissatisfied"
            style={"cursor: " + (currentFeedback !== "unknown" ? "unset" : "pointer")}
            on:click={() =>
              currentFeedback === "unknown" && sendFeedback(assistantMessageId, false)}
          >
            <Feedback strokeColor={currentFeedback === "bad" ? "red" : "#8E8E8E"} />
          </button>
        {/if}
        {#if ["unknown", "good"].includes(currentFeedback)}
          <button
            class="rizo-message-feedback-satisfied"
            style={"cursor: " + (currentFeedback !== "unknown" ? "unset" : "pointer")}
            on:click={() => currentFeedback === "unknown" && sendFeedback(assistantMessageId, true)}
          >
            <Feedback strokeColor={currentFeedback === "good" ? "green" : "#8E8E8E"} />
          </button>
        {/if}
      </span>
    {/if}
    {#if role === "assistant" && !!assistantFeedback}
      <span class={"rizo-message-feedback-container"}>
        {#if !assistantFeedback.isPositive}
          <span class="rizo-message-feedback-dissatisfied">
            <Feedback strokeColor={"red"} />
          </span>
        {/if}
        {#if assistantFeedback.isPositive}
          <span class="rizo-message-feedback-satisfied">
            <Feedback strokeColor={"green"} />
          </span>
        {/if}
      </span>
    {/if}
  </div>
</div>

<style>
  .rizo-chat-box-message-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 5px 5px 0px 5px;
  }

  .rizo-left-aligned {
    align-self: flex-start;
  }
  .rizo-right-aligned {
    align-self: flex-end;
  }

  .rizo-message-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-size: 12px;
    padding: 10px;
    border-radius: 15px;
    font-size: 0.8rem;
    line-height: 20px !important;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-weight: normal;
  }

  :global(.rizo-message-content p) {
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 20px;
    white-space: pre-wrap;
    color: #000;
    margin: 0px;
  }

  :global(.rizo-message-content-user p) {
    color: var(--widget-font-color);
  }

  .rizo-chat-single-message {
    flex: 1;
    max-width: 290px;
  }
  .rizo-chat-single-message-expanded {
    max-width: 550px;
  }

  .rizo-chat-user-message {
    margin-left: 40px;
    color: #fff;
    border-radius: 10px 10px 0px 10px;
  }

  .rizo-chat-assistant-message {
    margin-right: 20px;
    color: #000;
    border-radius: 0px 10px 10px 10px;
  }

  .rizo-message-feedback-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    height: 24px;
    padding-top: 0;
  }

  .rizo-message-feedback-container > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
  }
  .rizo-message-feedback-dissatisfied {
    transform: scaleY(-1);
  }
</style>
